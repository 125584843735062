<template>
  <button
      :disabled=isDisabled
      :class="buttonClasses"
      @click="click"
  >
    <div v-if="loading || icon" class="button__icon">
    </div>
    <span :class="textClasses">
			<slot/>
      {{ text }}
		</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  components: {},
  props: {
    buttonStyle: {
      type: String,
      description: 'UX defined styling of a button',
      default: 'primary',
      options: [
        'primary', 'outline', 'secondary', 'destroy',
      ],
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Puts the button into a non-clickable greyed out state',
    },
    implied: {
      type: Boolean,
      description: 'Changes the styling of the button to be text like until hovered',
    },
    icon: {
      type: String,
      description: 'what font awesome icon',
    },
    loading: {
      type: Boolean,
      description: 'Add an spinning icon to the button',
    },
    ellipsis: {
      type: Boolean,
      description: 'Applies text-overflow:ellipsis to button text.',
    },
    text: {
      type: String,
      description: 'Text to display',
    },
  },
  data() {
    return {};
  },
  mounted() {
  },
  computed: {
    isDisabled(){
      return this.loading || this.disabled;
    },
    buttonClasses() {
      return {
        'button': true,
        'button--primary': !this.buttonStyle,
        [`button--${ this.buttonStyle }`]: this.buttonStyle,
        'button--dropdown': this.dropdown,
        'button--implied': this.implied,
        'button--reversed': this.icon && this.icon.position === 'right',
        'button--loading': this.loading,
        'button--has-icon': this.loading || this.icon,
      };
    },
    textClasses() {
      return {
        'button__content': true,
        'button__content--ellipsis': this.ellipsis,
      };
    },
    iconClasses() {
      if (!this.icon && !this.loading) return {};
      const toReturn = {};
      if (this.loading) {
        toReturn['fal fa-spinner-third'] = true;
      }
      if (this.icon && this.icon.icon) {
        toReturn[this.icon.icon] = true;
      } else {
        toReturn[this.icon] = true;
      }
      return toReturn;
    },
  },
  methods: {
    click(e) {
      if (this.disabled) return;
      this.$emit('click', e);
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.button__dropdown-popover {
  &__options__option {
    &:hover {
      color: $color-blue-light !important;
    }
  }
  .popover__content {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
@mixin bkg-img-transition($bkg1, $bkgHover, $bkgActive, $transTime: 0.2s) {
  &:before,
  &:after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity $transTime ease;
    width: 100%;
  }
  &:before {
    z-index: -2;
    background-image: #{$bkg1};
  }
  &:after {
    z-index: -1;
    opacity: 0;
  }
  &:hover {
    &:after {
      opacity: 1;
      background-image: #{$bkgHover};
    }
  }
  &:active {
    &:after {
      background-image: #{$bkgActive};
      opacity: 1;
    }
  }
}
.button {
  appearance: none;
  outline: none;
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  min-width: 120px;
  width: fit-content;
  height: 36px;
  border-radius: 4px;
  border: none;
  padding: 0 12px;
  color: $color-black;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  line-height: 14px;
  //text-shadow: 0 1px 2px rgba(0, 0, 0, .2);
  transition: all 0.2s ease 0s;
  white-space: nowrap;
  background: none;
  cursor: pointer;
  vertical-align: bottom;
  margin: 4px auto;
  $btn: &;
  &--reversed {
    flex-direction: row-reverse;
  }
  &:not(:last-child) {
  }
  &--has-icon {
    .button__content {
      display: block;
    }
  }
  &__content {
    /* we wnat the click event to come from the button not the content */
    pointer-events: none;
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    justify-content: center;
    text-transform: capitalize;

    &--ellipsis {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: left;
    }
  }
  &__icon {
    font-size: 18px;
    align-items: center;
    padding: 0 4px;
    justify-content: center;
  }
  &__dropdown {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 12px;
    height: calc(100% + 8px);
    width: 36px;
    padding: 0 8px;
    font-size: 24px;
    box-shadow: -1px 0 0 0 $color-shadow, inset 1px 0 0 0 $color-shadow;
    text-align: center;
  }
  &__dropdown-popover {
    &__options {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;
      text-shadow: none;
      white-space: nowrap;
      cursor: pointer;
      &__option {
        padding: 12px 48px 12px 12px;
        &:hover {
          background-color: $color-blue-light;
        }
      }
    }
  }
  &--loading:before {
    //content: ' ';
    //background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='spinner-third' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-spinner-third fa-w-16 fa-3x'%3E%3Cpath fill='currentColor' d='M460.115 373.846l-6.941-4.008c-5.546-3.202-7.564-10.177-4.661-15.886 32.971-64.838 31.167-142.731-5.415-205.954-36.504-63.356-103.118-103.876-175.8-107.701C260.952 39.963 256 34.676 256 28.321v-8.012c0-6.904 5.808-12.337 12.703-11.982 83.552 4.306 160.157 50.861 202.106 123.67 42.069 72.703 44.083 162.322 6.034 236.838-3.14 6.149-10.75 8.462-16.728 5.011z' class=''%3E%3C/path%3E%3C/svg%3E");
    //animation: fa-spin 2s infinite linear;
    //height: 25px;
    //width: 25px;
    //left: 0;
    //right: 0;
    //margin-left: auto;
    //margin-right: auto;
    //position: absolute;
    //background-repeat: no-repeat;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &--primary {
    background: white;
    text-transform: capitalize;
    &:hover {
      background: $color-blue-light !important;
      color: $color-white;
    }
    & .button__icon {
      fill: $color-white;
    }

  }
  &--outline {
    //box-shadow: inset 0 0 0 1px #00A7E1;
    border: 1px solid $color-blue;
    //text-shadow: none;
    color: $color-blue;
    & .button__icon {
      fill: $color-blue;
    }
    &:hover {
      background-color: #e6f6fd;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba(0, 167, 225, 0.4);
    }
    &:active {
      background-color: #E3E7E8;
    }
  }
  &--secondary {
    color: #0C303D;
    border: 1px solid $color-gray;
    & .button__icon {
      fill: #0C303D;
    }
    @include bkg-img-transition(
            "linear-gradient(151deg, #ECEFF1 0%, #DBE1E4 100%)",
            "linear-gradient(151deg, #F3F6F6 0%, #E4E5E6 100%)",
            "linear-gradient(151deg, #DFE3E4 0%, #CFD5D8 100%)"
    );
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }
    &:focus {
      box-shadow: 0 0 0 2px #B0B3B5;
    }
  }
  &--destroy {
    & .button__icon {
      fill: $color-white;
    }
    @include bkg-img-transition(
            "linear-gradient(151deg, #E4674F 0%, #CE412F 100%)",
            "linear-gradient(151deg, #E98E7C 0%, #D76758 100%)",
            "linear-gradient(151deg, #D5604A 0%, #C13C2C 100%)"
    );
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }
    &:focus {
      box-shadow: 0 0 0 2px #f1bbb4;
    }
  }
  $button: &;
  &--implied {
    border: 1px solid transparent;
    color: $color-white;
    &:hover{
      color: $color-black;
    }
    &:before, &:after {
      background: none;
    }
    & .button__dropdown {
      border-left: none;
      transition: border-left-width 0.2s ease, opacity 1s ease;
    }
    &#{$button}--destroy {
      color: $color-red;
      &:hover, &:focus {
        & .button__dropdown {
          border-left: 1px solid $color-red;
        }
        border-color: $color-red;
        @include bkg-img-transition(
                "none",
                "linear-gradient(151deg, #FDF6F5 0%, #FDF6F5 100%)",
                "linear-gradient(151deg, #D5604A 0%, #C13C2C 100%)"
        );
      }
      &:active {
        color: $color-black;
      }
    }
    &#{$button}--secondary {
      &:hover, &:focus {
        border-color: $color-gray;
        & .button__dropdown {
          border-left: 1px solid $color-gray;
        }
      }
    }
    &#{$button}--outline {
      &:hover {
        border-color: $color-blue;
      }
    }
    &#{$button}--primary {
      border: none;
      &:not(:hover) {
        color: $color-blue;
      }
    }
  }
}
</style>