<template>
  <div class="projects">
    <TextDisplay class="projects_subtitle" font-style="title" :text="title"/>
    <div class="projects_list">
      <Profile class="projects_list_item" :item="p" :key="p.name" v-for="p in sortedProjects"/>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/shared/Profile";
import TextDisplay from "@/components/shared/Text";

export default {
  name: "Projects",
  components: {TextDisplay, Profile},
  data() {
    return {
      title: "What we are working on",
      projects: [
        {
          name: "Galaktic Gang",
          image: require('../assets/project_pics/galaktic-gang.jpg'),
          // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          links: {
            discord: "https://discord.gg/galakticgang",
            web: "https://www.galakticgang.com/",
            twitter: " https://www.twitter.com/galakticgangnft",
            opensea: "https://opensea.io/collection/galaktic-gang",
            instagram: "https://www.instagram.com/galakticgangnft",
          },
        },
        {
          name: "Digital Arms",
          image: require('../assets/project_pics/digital-arms.jpeg'),
          // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          links: {
            twitter: "https://twitter.com/DigitalArmsNFT",
            web: "https://www.hunter-token.com/",
            instagram: "https://www.instagram.com/digital_arms/ ",
            discord: "https://discord.gg/digital-arms",
          },
        },
        {
          name: "Ghoulies",
          image: require('../assets/project_pics/ghoulies.png'),
          // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          links: {
            web: "https://ghouliesnft.com/",
            opensea: "https://opensea.io/collection/ghouliesnft",
            twitter: "https://twitter.com/GhouliesN",
            instagram: "https://www.instagram.com/ghouliesnft/",
          },
        },
        // {
        //   name: "5252",
        //   image: require('../assets/profile_pics/blank.png'),
        //   description: "More Info Coming Soon",
        //   links: {},
        // },
      ],
    };
  },
  computed: {
    sortedProjects() {
      let m = this.projects;
      m.sort((a, b) => a.name.localeCompare(b.name));
      return m;
    },
  },
}
</script>

<style scoped lang="scss">
.projects {
  &_title {

  }

  &_subtitle {

  }

  &_list {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &_item {
      margin-bottom: 5%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 4px;
      padding: 20px;

      @media screen and (max-width: $screen-medium) {
        width: 80%;
        margin-bottom: 0;
      }

    }
  }
}


</style>