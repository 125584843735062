<template>
  <div :class="textClasses" @click="clicked">{{ text }}</div>
</template>

<script>
export default {
  name: "TextDisplay",
  props: {
    fontStyle: {
      type: String,
      description: 'UX defined styling of a button',
      default: 'normal',
      options: [
        'normal', 'title', 'subtitle', 'header', 'router', "integris", 'exec', 'label', 'mantra',
          'medium'
      ],
    },
    uppercase:{
      type: Boolean,
      description: "should the text be all uppercase",
    },
    hover: {
      type: Boolean,
      description: "true if we change classes on hover",
    },
    text: {
      type: String,
      description: 'The text to display',
    }
  },
  created() {
    // console.log("hover", this.hover);
    // console.log("text", this.text);
    // console.log("font-style", this.fontStyle);
  },
  methods:{
    clicked(e){
      this.$emit("click", e);
    }
  },
  computed: {
    textClasses() {
      // console.log("getting classes");
      return {
        [`text--${this.fontStyle}`]: this.fontStyle,
        'text-hoverable': this.hover,
        'text-uppercase': this.uppercase,
      };
    },
  }
}
</script>

<style scoped lang="scss">
.text {
  &-hoverable {
    color: blue;
    text-decoration: underline;

    &:hover {
      color: purple;
    }
  }

  &-uppercase{
  }

  &--normal {
    font-size: 20px;
    color: $color-white;
  }
  &--medium{
    text-decoration: underline;
    font-size: 24px;
    color: $font-color-grey;
  }

  &--title {
    font-size: 36px;
    text-transform: uppercase;
  }

  &--subtitle {
    font-size: 16px;
    color: $font-color-grey;
  }

  &--router {
    font-size: 24px;
    text-transform: uppercase;

    @media screen and (max-width: $screen-small) {
      font-size: 12px;
    }
  }

  &--exec {
    font-style: italic;
    text-decoration: underline;
    font-size: 16px;
    color: $font-color-grey;
  }

  &--label {
    font-size: 20px;
  }

  &--integris {
    font-size: 70px;
    color: $color-blue;
  }

  &--mantra {
    font-style: italic;
    font-weight: 100;
    font-size: 18px;
    font-family: URW Chancery L, cursive;
    color: $font-color-grey;
  }
}
</style>