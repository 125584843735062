<template>
  <img v-if="brand === 'opensea'" :class="classes" @click="handleClick"
       :src="require('../../assets/images/opensea.png')">
  <i v-else :class="classes" @click="handleClick"/>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    brand: {
      type: String,
      description: 'twitter, opensea, twitch, discord, web, instagram',
      default: 'twitter',
      options: [
        'twitter', 'opensea', 'twitch', 'discord', 'web', 'instagram', 'email',
      ],
    },
    link: {
      type: String,
      description: 'link to click',
    }
  },
  methods: {
    handleClick() {
      console.log(this.link);
      if (this.link !== '') {
        window.open(this.link);
      }
    },
  },
  computed: {
    classes() {
      if (this.brand === 'email' || this.brand === 'envelope') {
        return "icon icon--" + this.brand + " fa fa-envelope";
      }
      if (this.brand === 'opensea') {
        return "icon icon--" + this.brand;
      }
      if (this.brand === 'web') {
        return "icon icon--" + this.brand + " fa fa-globe";
      }
      return "icon icon--" + this.brand + " fab fa-" + this.brand;
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  transition: all .1s ease-in-out;
  font-size: 48px;
  padding: 5px;
  border-radius: 8px;
  height: fit-content;
  margin: 2px;

  &:hover {
    cursor: pointer;
  }

  &--twitter {
    color: $color-twitter;

    &:hover {
      background: $color-twitter;
      color: $color-white;
    }
  }

  &--opensea {
    width: 50px;

    &:hover {
      background: $color-white;
    }
  }

  &--twitch {
    color: $color-twitch;

    &:hover {
      background: $color-twitch;
      color: $color-white;
    }
  }

  &--discord {
    color: $color-discord;

    &:hover {
      background: $color-discord;
      color: $color-white;
    }
  }

  &--web {
    color: $color-web;

    &:hover {
      background: $color-web;
      color: $color-white;
    }
  }

  &--email {
    color: $color-email;

    &:hover {
      background: $color-email;
      color: $color-white;
    }
  }

  &--instagram {
    padding: 4px 6px;
    height: 48px;
    width: 48px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(45deg, #f09433, #bc1888);
    transition: $trans-all;

    &:hover {
      -webkit-background-clip: inherit;
      -webkit-text-fill-color: inherit;
    }

    &:before {
      content: "\f16d";
      border-radius: 8px;
    }
  }
}
</style>