<template>
  <div class="about">
    <div class="about_verbiage">
      <TextDisplay :text="title" uppercase font-style="title"/>
      <TextDisplay class="about_verbiage-left" :text="text" font-style="subtitle"/>
    </div>
    <img class="about_logo" :src="require('../assets/images/Integris logo mark.png')"/>
  </div>
</template>

<style lang="scss" scoped>
.about {
  display: flex;
  min-height: fit-content;

  @media screen and (max-width: $screen-medium) {
    display: flex;
    flex-direction: column;
  }

  &_verbiage {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-left {
      text-align: left;
    }
  }

  &_logo {
    height: auto;
    width: 66%;
    max-height: 56vh;
    margin: auto;
    @media screen and (max-width: $screen-medium) {
    }
  }
}
</style>
<script>

import TextDisplay from "@/components/shared/Text";

export default {
  components: {TextDisplay},
  data() {
    return {
      title: "Who we are",
      text: "We are a team of experienced investors and creators who want to share our wealth\n" +
          "        of experience to the benefit of our clients, and to the communities that we support.\n" +
          "\n" +
          "        We all entered the market\n" +
          "        at different times and have had our share of ups and downs. We've experienced the high of seeing an project or\n" +
          "        investment grow significantly, and the lows of being victims of rug pulls. That is why we built Integris!\n" +
          "\n" +
          "        We define ourselves by our integrity and drive to make this space, and this world, a better place! Join us on\n" +
          "        this journey!"
    }
  }
}
</script>