<template>
  <div class="profile">
    <div>
      <div class="profile_name">
        <TextDisplay :text="item.name" font-style="label"/>
      </div>

      <img class="profile_image" :src="item.image" :alt="item.name"/>
      <TextDisplay class="profile_desc" :text="item.description" font-style="subtitle"/>
      <TextDisplay class="profile_mantra" v-if="item.mantra" font-style="mantra" :text="item.mantra"/>

    </div>
    <div v-if="hasLinks" class="profile_links">
      <Icon :key="l.brand" :brand="l.brand" v-for="l in sortedLinks" :link="l.link"/>
    </div>
    <div v-else class="profile_links"/>
  </div>
</template>

<script>
import Icon from "@/components/shared/Icon";
import TextDisplay from "@/components/shared/Text";

export default {
  name: "Profile",
  components: {TextDisplay, Icon},
  props: {
    item: {
      type: Object,
      description: 'UX defined styling of a button',
    },
  },
  methods: {},
  computed: {
    sortedLinks() {
      let links = [];
      if (this.item.links.twitter) {
        links.push({brand: 'twitter', link: this.item.links.twitter});
      }
      if (this.item.links.opensea) {
        links.push({brand: 'opensea', link: this.item.links.opensea});
      }
      if (this.item.links.twitch) {
        links.push({brand: 'twitch', link: this.item.links.twitch});
      }
      if (this.item.links.discord) {
        links.push({brand: 'discord', link: this.item.links.discord});
      }
      if (this.item.links.web) {
        links.push({brand: 'web', link: this.item.links.web});
      }
      if (this.item.links.instagram) {
        links.push({brand: 'instagram', link: this.item.links.instagram});
      }
      if (this.item.links.email) {
        links.push({brand: 'email', link: this.item.links.email});
      }

      links.sort((a, b) => a.brand - b.brand);
      return links;
    },
    hasLinks() {
      let keys = ['twitter', 'opensea', 'twitch', 'discord', 'web', 'instagram', 'email'];
      for (const k of keys) {
        if (Object.prototype.hasOwnProperty.call(this.item.links, k)) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>

<style scoped lang="scss">

.profile {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_name {
    display: flex;
    justify-content: space-around;
  }

  &_exec {
    margin: auto 0;
  }

  &_image {
    margin: auto;
    padding: 10px;
    height: 200px;
    max-width: 90%;
    width: auto;
    border-radius: 20px;
    transition: transform .1s; /* Animation */
    &:hover {
      //transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
  }

  &_desc {
    margin: 0 auto;
    text-align: left;

    @media screen and (max-width: $screen-small) {
      min-height: 0;
    }
  }

  &_mantra {
    padding-top: 32px;
  }

  &_links {
    display: flex;
    justify-content: space-evenly;
    height: 60px;
  }
}
</style>