<template>
  <div class="services">
    <!--    <img :src="require('../assets/images/packages.jpg')"/>-->
    <div class="services_verbiage">
      <TextDisplay class="services_verbiage_title" :text="title" font-style="title"/>
      <TextDisplay class="services_verbiage_text" :text="para1" font-style="subtitle"/>
      <TextDisplay class="services_verbiage_text" :text="para2" font-style="subtitle"/>
    </div>
  </div>
</template>

<script>
import TextDisplay from "@/components/shared/Text";

export default {
  name: "Services",
  components: {TextDisplay},
  data() {
    return {
      title: "What we do",
      para1: "We offer a variety of support packages and a la carte services that allow us to support your project and vision in the way that best suits you.",
      para2: "We also are willing to negotiate custom packages and support if our standard packages don't meet your needs. Feel free to contact us and request a consultation!",
    }
  }
}
</script>

<style scoped lang="scss">
.services {
  min-height: fit-content;

  @media screen and (max-width: $screen-medium) {
    flex-direction: column-reverse;
  }

  &_verbiage {
    display: flex;
    flex-direction: column;


    &_text {
      text-align: left;
    }

    &_title {
    }
  }
}
</style>