<template>
  <div class="beliefs">
    <TextDisplay class="beliefs_title" font-style="title" :text="title"/>
    <TextDisplay class="beliefs_text" font-style="subtitle" :text="text"/>
  </div>
</template>

<script>
import TextDisplay from "@/components/shared/Text";
export default {
  name: "Beliefs",
  components: {TextDisplay},
  data(){
    return {
      title: "What we Believe In",
      text:"We believe that blockchain is the future, and we want to help passionate people stake their claim in this space!",
    }
  }
}
</script>

<style scoped lang="scss">
.beliefs {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  &_title {

  }

  &_text {
    width: 50%;
  }
}
</style>