<template>
  <div class="contact">
    <TextDisplay font-style="title" :text="title"/>
    <TextDisplay font-style="subtitle" :text="text"/>
    <Button class="contact-accordion contact-accordion-button" :style="getButtonStyle" text="Contact Us"
            @click="clicked"/>
    <div class="contact-accordion" :style="getFormStyle">
      <div class="contact-form">
        <div class="contact-form-left">
          Name: <input class="contact-form-input" type="text" v-model="name" placeholder="Name or Username (required)">
          Email: <input class="contact-form-input" type="text" v-model="email" placeholder="required">
          Description: <textarea class="contact-form-input contact-form-area" v-model="description"
                                 placeholder="Tell us about your project and what you're looking for"/>
        </div>
        <div class="contact-form-right">
          Twitter: <input class="contact-form-input" type="text" v-model="twitter" placeholder="optional">
          Discord: <input class="contact-form-input" type="text" v-model="discord"
                          placeholder="optional (eg. Integris#5252)">
          Telegram: <input class="contact-form-input" type="text" v-model="telegram" placeholder="optional">
        </div>
      </div>
      <Button
          @click="submitForm"
          class="contact-form-button"
          text="Submit"
          :disabled=isSubmitDisabled
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/shared/Button";
import TextDisplay from "@/components/shared/Text";

export default {
  name: "ContactUs",
  components: {TextDisplay, Button},
  data() {
    return {
      title: "Contact us to schedule a consultation!",
      text: "We can't wait to hear about your project and get started in helping you make your dreams come true! Reach out to us via this form, or on any of our social media platforms!",
      showForm: false,
      name: null,
      email: null,
      twitter: '',
      discord: '',
      telegram: '',
      description: '',
    }
  },
  methods: {
    clicked() {
      this.showForm = !this.showForm;
    },
    submitForm() {
      console.log("not submitted, in progress");
      let link = "mailto:bodohmobile@gmail.com?subject=" + this.subject + "&body=" + this.body
      window.open(link);
    },
  },
  computed: {
    subject() {
      return "Request for Integris Details";
    },
    body() {
      return '<html>' +
          '<p>Name:' + this.name + '</p>' +
          '<p>Email:' + this.email + '</p>' +
          '<p>twitter:' + this.twitter + '</p>' +
          '<p>discord:' + this.discord + '</p>' +
          '<p>telegram:' + this.telegram + '</p>' +
          '<p>description:' + this.description + '</p>' +
          '</html>';
    },
    getButtonStyle() {
      return {
        "max-height": this.showForm ? 0 : "10vh",
        "transition": "all .2s ease",
        "padding": this.showForm ? 0 : "28px 36px",
      }
    },
    getFormStyle() {
      return {
        "max-height": !this.showForm ? 0 : "100vh",
      }
    },
    isSubmitDisabled() {
      // check email validity
      return this.name === '' ||
          !this.isValidEmail ||
          this.description === '';
    },
    isValidEmail() {
      return String(this.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }
  }
}
</script>

<style scoped lang="scss">
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-accordion {
    overflow: hidden;
    transition: all .5s ease;

    &-button {
      margin: 12px auto;
      font-size: 24px;
      font-family: 'Brandon Grotesque Black', sans-serif;
      border-radius: 8px;
    }
  }

  &-form {
    display: flex;
    justify-content: center;

    &-button {
      margin: 12px;
      font-size: 24px;
      padding: 28px 36px;
      font-family: 'Brandon Grotesque Black', sans-serif;
      border-radius: 8px;
    }

    &-area {
      resize: none;
      overflow: auto;
    }

    &-input {
      color: whitesmoke;
      background: #424242;
      border: 0;
      border-radius: 20px;
      font-size: 15px;
      padding: 9px;
      font-family: 'Brandon Grotesque Black', sans-serif;

    }

    &-left, &-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto;
      width: 45%;
    }
  }
}
</style>