<template>
  <div :class="classes">
    <IntegrisLogo class="header_block"/>
    <div class="header_block">
      <TextDisplay class="header_route" font-style="router" @click="scrollToElement('about')" text="About"/>
      <TextDisplay class="header_route" font-style="router" @click="scrollToElement('services')" text="Services"/>
      <TextDisplay class="header_route" font-style="router" @click="scrollToElement('team')" text="Team"/>
      <TextDisplay class="header_route" font-style="router" @click="scrollToElement('portfolio')" text="Portfolio"/>
      <TextDisplay class="header_route" font-style="router" @click="scrollToElement('contact')" text="Contact"/>
    </div>
  </div>
</template>

<script>
import IntegrisLogo from "@/components/shared/IntegrisLogo";
import TextDisplay from "@/components/shared/Text";

export default {
  name: "Header",
  components: {TextDisplay, IntegrisLogo},
  props: {
    fixed: {
      type: Boolean,
      description: 'whether the header should stick at the top of the page',
    }
  },
  methods: {
    scrollToElement(e) {
      let element = document.getElementById(e);
      window.scrollTo(0, element.offsetTop - 120);
    },
  },
  computed: {
    classes() {
      let c = 'header';
      if (this.fixed) {
        c += ' header--fixed';
      }
      return c
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  transition: all .2s ease-in;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media screen and (max-width: 690px) {
    display: block;
  }

  &--fixed {
    position: sticky;
    top: 0;
    background: $color-background-grey;
    z-index: 1;

    @media screen and (max-width: 690px) {
      border-bottom: 1px solid black;
    }
  }

  &_block {
    padding: 5px;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
  }

  &_route {
    display: flex;
    margin: auto 4px;
    padding: 12px 24px;
    border-radius: 4px;
    transition: $trans-all;

    @media screen and (max-width: $screen-small) {
      font-size: 12px;
      border: 1px solid $color-gray-light;
      margin: 4px;
    }

    @media screen and (max-width: 770px) {
      font-size: 14px;
    }

    &:hover {
      background: $color-blue-light;
    }
  }

}
</style>