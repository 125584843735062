<template>
  <div class="footer">
    <Icon brand="discord" @click="openDiscord"/>
    <Icon brand="twitter" @click="openTwitter"/>
    <Icon brand="instagram" @click="openInsta"/>
    <Icon brand="email" @click="openEmail"/>
  </div>
</template>

<script>
import Icon from "@/components/shared/Icon";

export default {
  name: "Footer",
  components: {Icon},
  methods: {
    openDiscord() {
      window.open('https://discord.gg/gPTz2KmQ9p');
    },
    openTwitter() {
      window.open('https://twitter.com/Integris_NFT_CM');
    },
    openInsta() {
      window.open('https://twitter.com/Integris_NFT_CM');
    },
    openEmail() {
      window.open('https://twitter.com/Integris_NFT_CM');
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  width: $app-width;
  min-height: 1vh;
  padding: 35px 0;
  margin: 0 auto;

  & i {
    margin: 0 10px;
  }

}
</style>