<template>
  <div class="app">
    <div class="app_content">
      <Header fixed/>
      <Splash/>
      <Beliefs id="Beliefs" class="app_content_section reveal"/>
      <About id="about" class="app_content_section reveal"/>
      <Services id="services" class="app_content_section reveal"/>
      <Team execs id="execs" class="app_content_section reveal"/>
      <Team id="team" class="app_content_section reveal"/>
      <Projects id="portfolio" class="app_content_section reveal"/>
      <ContactUs id="contact" class="app_content_section reveal"/>
      <Footer id="Footer" class="app_content_section"/>
    </div>
  </div>
</template>

<script>

import Footer from "@/components/shared/Footer";
import Splash from "@/views/Splash";
import Beliefs from "@/views/Beliefs";
import About from "@/views/About";
import Header from "@/views/Header";
import Services from "@/views/Services";
import Team from "@/views/Team";
import Projects from "@/views/Projects";
import ContactUs from "@/views/ContactUs";

export default {
  name: "App",
  components: {ContactUs, Projects, Team, Services, Header, About, Beliefs, Splash, Footer},
  data() {
    return {
      sections: null,
    }
  },
  methods: {
    reveal() {
      var reveals = document.querySelectorAll(".reveal");
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = windowHeight * .1;
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.reveal);
    this.reveal();
  },
  destroyed() {
    window.removeEventListener('scroll', this.reveal);
  },
}
</script>


<style lang="scss">
@font-face {
  font-family: 'Brandon Grotesque Black', sans-serif;
  src: url('http://fonts.cdnfonts.com/css/brandon-grotesque-regular');
  //src: url('../assets/fonts/across_the_road/across_the_road-webfont.eot?#iefix') format('embedded-opentype'),
  //url('../assets/fonts/across_the_road/across_the_road-webfont.woff2') format('woff2'),
  //url('../assets/fonts/across_the_road/across_the_road-webfont.woff') format('woff'),
  //url('../assets/fonts/across_the_road/across_the_road-webfont.ttf') format('truetype'),
  //url('../assets/fonts/across_the_road/across_the_road-webfont.svg#across_the_roadregular') format('svg');
  font-weight: bold;
  font-style: normal;
}

.app {
  scroll-behavior: smooth;
  font-family: 'Brandon Grotesque Black', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  text-align: center;
  background-color: $color-background-grey;
  color: $font-color-light;
  min-height: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;

  &_content {
    font-size: 18px;

    &_section {
      padding: 2%;
      min-height: 30vh;
      width: $app-width;
      margin: 0 auto;
    }
  }
}

.reveal {
  position: relative;
  transform: scale(0.8);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: scale(1);
  opacity: 1;
}

#nav {
  width: $app-width;
  margin: 0 auto;
  align-items: flex-start;
  padding: 8px 0;
  display: inline-flex;
  border-bottom: $border-blue;
}

#nav a {
  color: $color-blue;

  &:hover {
    color: $color-grey-dark;
  }
}

#nav a.router-link-exact-active {
  color: $color-grey;
}

.nav-item {
  margin: auto;
  font-size: 36px;
  transition: $trans-all;
}

</style>