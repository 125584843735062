<template>
  <div class="splash">
    <TextDisplay class="splash_title" font-style="title" :text="title"/>
    <TextDisplay class="splash_text" font-style="subtitle" :text="text"/>
    <Button text="Learn More"/>
  </div>
</template>

<script>
import Button from "@/components/shared/Button";
import TextDisplay from "@/components/shared/Text";

export default {
  name: "Splash",
  components: {TextDisplay, Button},
  data() {
    return {
      title: "Build the project of your dreams!",
      text: "We specialize in helping creators build, maintain, and revitalize the crypto and NFT projects of their dreams.\n" +
          "      We offer a wide variety of services that allow us to help you make your dreams come true!"
    }
  }
}
</script>

<style scoped lang="scss">
.splash {
  height: 60vh;
  width: 95%;
  background-image: url("../assets/images/net-splash.jpg");
  background-position: 0 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto 0;

  &_title {
    font-size: 32px;
  }

  &_text {
  }
}
</style>