<template>
  <div class="integrislogo">
    <img class="integrislogo-logo" id="spinningLogo" @click="clickLogo()"
         :src="require('../../assets/images/Integris logo mark.png')"/>
    <TextDisplay class="integrislogo-text" @click="clickLogo()" font-style="integris" text="INTEGRIS"/>
  </div>
</template>

<script>
import TextDisplay from "@/components/shared/Text";
export default {
  name: "IntegrisLogo",
  components: {TextDisplay},
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    clickLogo() {
      window.scrollTo(0, 0);
    },
    handleScroll(e) {
      // Any code to be executed when the window is scrolled
      var leftgear = document.getElementById("spinningLogo");
      var multiplier = 0.1;
      leftgear.style.transform = "rotate(" + e.path[1].scrollY * multiplier + "deg)";
    }
  },
}
</script>

<style lang="scss" scoped>
.integrislogo {
  &-logo {
    padding: 7px 5px;
    vertical-align: middle;
    height: 85px;
    transition: all .3s ease-in, font-size .2s ease-in-out;

    @media screen and (max-width: $screen-medium) {
      height: 50px;
    }
    @media screen and (max-width: 700px) {
      height: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-text {
    transition: all .2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $screen-medium) {
      font-size: 38px;
    }
    @media screen and (max-width: 700px) {
      font-size: 36px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>